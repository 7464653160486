import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Projects" mdxType="Title" />
    <ul>
      <li parentName="ul">
        <a target="_blank" rel="noreferrer" href="https://www.npmjs.com/package/code-harmony">Code Harmony</a>
      </li>
      <li parentName="ul">
        <a target="_blank" rel="noreferrer" href="https://gitlab.com/aossie/CarbonFootprint-API">CarbonFootprint - API</a>
      </li>
      <li parentName="ul">
        <a target="_blank" rel="noreferrer" href="https://github.com/yogdaan/blockchain-certification">Generation and validation of certificates</a>
      </li>
      <li parentName="ul">
        <a target="_blank" rel="noreferrer" href="https://github.com/thakursaurabh1998/bloom-filter">Bloom Filter</a>
      </li>
      <li parentName="ul">
        <a target="_blank" rel="noreferrer" href="https://github.com/thakursaurabh1998/imprints">Imprints</a>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      